import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * Welles Wilder Smoothing
 *
 * @param series
 * @param length
 * @returns
 */
export const wwma = (series: PineJS.NewVar<number>, length: number) => {
  const smoothFactor = 2 / (length + 1)
  const wwmaValue = series.get(0) * smoothFactor + series.get(1) * (1 - smoothFactor)
  return wwmaValue
}
