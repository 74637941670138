import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * 計算序列的母體變異數
 *
 * @param this
 * @param series 序列
 * @param length 期數
 * @returns number
 */
export function variance(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
): number {
  return this.PineJS.Std.variance(series, length, this._context)
}
